<template>
  <div>
    <strong>{{ getPrice() }}</strong>
    <span class="ml-2 text-warning" v-if="needPriceCorrection()">
      <font-awesome-icon icon="balance-scale" />
    </span>
  </div>
</template>

<script>
import get from "lodash/get";
import { mapGetters } from "vuex";
import { formatPrice } from "../../../helpers/common";

export default {
  name: "UserProductTableVariantPrice",

  data() {
    return {
      data: {},
    };
  },
  computed: {
    ...mapGetters("connections", ["currencySymbol"]),
  },

  methods: {
    getPrice() {
      return formatPrice(this.data.ppm.price, this.currencySymbol).format();
    },

    needPriceCorrection() {
      return (
        get(this.data, "ppl.pw", false) ||
        get(this.data, "ppl.productproducer.pw", false)
      );
    },
  },
};
</script>
